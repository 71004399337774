import React from "react";
import AppWrapper from "../../commons/app-wrapper";
import "../../../styles/v1/global.scss";
import withAppContext from "../../../contexts/app.context";

interface Props {
  children: React.ReactNode;
}

const V1Entry: React.FC<Props> = (props: Props) => {
  if (process.env.GATSBY_VERSION === "v1") {
    //CSS Version Imports
    require("../../../styles/v1/index.scss");
  }

  return <AppWrapper>{props.children}</AppWrapper>;
};

export default withAppContext(V1Entry);
