import React from "react";
import styles from "./LinkButton.module.scss";
import { Link } from "gatsby";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  route: string;
  dataTestE2E?: string;
}

const LinkButton: React.FC<Props> = (props: Props) => {
  return (
    <Link
      to={props.route}
      className={cx(styles.LinkButton, props.className ?? "")}
      data-test-e2e={props.dataTestE2E}
    >
      {props.children}
    </Link>
  );
};

export default LinkButton;
