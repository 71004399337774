import React, { useState, RefObject } from "react";
import { MenuOverlayOptions } from "../../../enums/menu-overlay-options";
import V2Entry from "../v2-entry";
import AFGCHeader from "../afgc-header/AFGCHeader";
import Footer from "../footer/Footer";
import MenuOverlay from "../menu-overlay/MenuOverlay";

interface Props {
  children: React.ReactNode;
  menuSelected?: MenuOverlayOptions;
  handleScroll?: Function;
  reference?: RefObject<HTMLDivElement>;
}

const Layout: React.FC<Props> = (props: Props) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handleClose = () => setIsPopUpOpen(false);

  return (
    <V2Entry handleScroll={props.handleScroll} reference={props.reference}>
      <AFGCHeader clicked={() => setIsPopUpOpen(true)} />
      {props.children}
      <Footer />
      <MenuOverlay
        closePopUp={handleClose}
        isOpen={isPopUpOpen}
        selected={props.menuSelected}
      />
    </V2Entry>
  );
};

export default Layout;
