import React from "react";
import styles from "./AFGCHeader.module.scss";
import LogoIcon from "../../../images/icons/v1/logo.inline.svg";
import FilterIcon from "../../../images/icons/v1/filter.inline.svg";
import CrossIcon from "../../../images/icons/cross-icon.inline.svg";
import AFGCLogo from "../../commons/afgc-logo/AFGCLogo";
import cx from "classnames";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import { IconSizes } from "../../../enums/icon-sizes";

interface Props {
  children?: React.ReactNode;
  showListIcon?: boolean;
  iconClick?: () => void;
  useHeader?: boolean;
  close?: boolean;
  containerClassName?: string;
  contentClassName?: string;
}

const AFGCHeader: React.FC<Props> = (props: Props) => {
  const headerComponent = () => (
    <div
      className={cx(
        styles.header,
        props.useHeader && styles.useHeader,
        props.contentClassName ?? ""
      )}
    >
      <AFGCLogo>
        <LogoIcon />
      </AFGCLogo>
      <div className={styles.header__children}>{props.children}</div>
      <SVGContainer
        onClick={() => {
          if (props.iconClick) {
            props.iconClick();
          }
        }}
        className={cx(
          styles.header__filterIcon,
          !props.showListIcon && styles.hide
        )}
        iconSize={IconSizes.IC__filter}
      >
        {props.close ? <CrossIcon /> : <FilterIcon />}
      </SVGContainer>
    </div>
  );

  if (props.useHeader) {
    return (
      <header className={cx(styles.container, props.containerClassName ?? "")}>
        {headerComponent()}
      </header>
    );
  }

  return (
    <section className={cx(styles.container, props.containerClassName ?? "")}>
      {headerComponent()}
    </section>
  );
};

AFGCHeader.defaultProps = {
  showListIcon: true,
  useHeader: true,
  close: false,
};

export default AFGCHeader;
