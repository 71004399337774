import React from "react";

import Layout from "../../layout/Layout";
import NotFoundComponent from "../../../commons/404";

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundComponent />
    </Layout>
  );
};

export default NotFoundPage;
