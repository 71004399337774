import React from "react";
import NotFoundPage1 from "../components/v1/pages/404";
import NotFoundPage2 from "../components/v2/pages/404";

const NotFoundPage = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <NotFoundPage1 />;
  } else {
    return <NotFoundPage2 />;
  }
};

export default NotFoundPage;
