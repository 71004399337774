import React from "react";
import cx from "classnames";
import styles from "./404.module.scss";
import ErrorImage from "../../../images/errorPage.svg";
import LinkButton from "../../v2/link-button/LinkButton";
import { RouteLinks } from "../../../utils/route-links";
import SEO from "../seo";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  {
    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__404" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
        metaImageName
      }
    }
  }
`;

const NotFound = () => {
  const queryResult = useStaticQuery(query);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = queryResult.seo.frontmatter;

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={RouteLinks[404]}
      />
      <main
        className={cx("column", styles.errorPage)}
        data-test-e2e="notFound-container"
      >
        <figure className={styles.imageWrap}>
          <img src={ErrorImage} alt="404 Error" />
        </figure>
        <h2>Page not found...</h2>
        <p className="open">Reload or go to homepage</p>
        <LinkButton
          route={RouteLinks.index}
          className="open"
          dataTestE2E="home-button"
        >
          Go to Homepage
        </LinkButton>
      </main>
    </>
  );
};

export default NotFound;
